import MuiDivider, { type DividerProps as MuiDividerProps } from '@mui/material/Divider';
import { forwardRef } from 'react';
export interface DividerProps extends MuiDividerProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLHRElement>;
}
export const Divider = forwardRef<HTMLHRElement, DividerProps>(({
  children,
  ...rest
}, ref) => {
  return <MuiDivider ref={ref} {...rest}>
      {children}
    </MuiDivider>;
});